<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          per-page="0"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <b-modal
          id="modal"
          size="xl"
          scrollable
          ok-only
          ok-variant="lisaweb"
          header-bg-variant="lisaweb"
          no-close-on-backdrop
        >
          <div>
            <b-tabs content-class="pt-1" lazy v-model="tabIndex">
              <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                @click="setTab(tab, index)"
                :title="tab.text"
                :active="index === tabIndex"
                title-item-class="lisaweb"
              >
                <keep-alive>
                  <component
                    v-if="selected === tab.name"
                    :is="selected"
                    :resourceId="modalResourceId"
                    @fetch="fetch"
                  ></component>
                </keep-alive>
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import template_lisaweb from "./template_lisaweb";
import BaseIcon from "@/components/BaseIcon";
import General from "@/components/profile/lisacomunica/logs/details/General";

export default {
  name: "LisacomunicaLogTable",
  extends: template_lisaweb,
  data() {
    return {
      tabs: [{ name: "General", text: "Generale" }],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    General,
  },
};
</script>
