<template>
  <div class="mt-1 mx-3">
    <log> </log>
  </div>
</template>

<script>
import Log from "@/components/profile/lisacomunica/logs/index.vue";
import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Log,
  },
  created() {
    this.notifyQueue({
      text: "LisaComunica",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Log",
      path: "lisacomunica_log",
      level: 2,
    });
  },
};
</script>
